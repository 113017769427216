import { FC, MouseEvent, ReactElement, useContext, useState } from "react";
import { Bookies } from "@/components/Bookies/Bookies";
import { Selections } from "@/components/Selections/Selections";
import { BookieDisplay } from "@/components/BookieDisplay/BookieDisplay";
import { getActiveBrand } from "@/lib/brand";
import { BetSlipStateContext } from "@/contexts/betSlipContext";
import { BetSlipState } from "@/types/betslip";
import styles from "./BetslipTabs.module.scss";

export interface BetslipStakeState {
  stakeValue: string;
  // eslint-disable-next-line no-unused-vars
  setStakeValue: (value: string) => void;
  stakeInput: "button" | "input";
  // eslint-disable-next-line no-unused-vars
  setStakeInput: (value: "button" | "input") => void;
}

export const BetslipTabs: FC<BetslipStakeState> = ({
  stakeValue,
  setStakeValue,
  stakeInput,
  setStakeInput,
}): ReactElement => {
  const { bookmakers, activeCommonAndEventIdPairs } = useContext(
    BetSlipStateContext
  ) as BetSlipState;

  const brand = getActiveBrand();
  const operatorTerm = brand === "fp" ? "Sportsbooks" : "Bookies";
  const [activeButton, setActiveButton] = useState<string>(operatorTerm);
  const buttons = [operatorTerm, "Selections"];

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const buttonName = event.currentTarget.name;

    setActiveButton(buttonName);
  };

  function renderTabs(tab: string) {
    const tabMap: Map<string, React.ReactNode> = new Map([
      ["Selections", <Selections key={tab} />],
      [
        operatorTerm,
        <Bookies
          key={tab}
          stakeValue={stakeValue}
          setStakeValue={setStakeValue}
          stakeInput={stakeInput}
          setStakeInput={setStakeInput}
          selectionCount={activeCommonAndEventIdPairs.length}
        >
          <>
            {bookmakers.map((bookie, index) => {
              return (
                <BookieDisplay
                  key={bookie.bookmaker}
                  bookmaker={bookie.bookmaker}
                  bookmakerLogoURL={bookie.bookmakerLogoURL}
                  odds={bookie.odds}
                  oddsDecimal={bookie.oddsDecimal}
                  oddsAmerican={bookie.oddsAmerican}
                  betslipURL={bookie.betslipURL}
                  offers={bookie.offers}
                  stake={Number(stakeValue)}
                  bestOdds={index === 0}
                />
              );
            })}
          </>
        </Bookies>,
      ],
    ]);
    return tabMap.get(tab);
  }

  return (
    <div data-testid="betslipTabs">
      <div className={styles["tab__button-container"]}>
        {buttons.map((button) => (
          <button
            type="button"
            key={button}
            aria-pressed={button === activeButton}
            onClick={onClick}
            name={button}
            className={`${styles["tab__button"]} ${styles["underline"]}`}
          >
            {button}
          </button>
        ))}
      </div>
      <div className={styles["tab__content-container"]}>
        {renderTabs(activeButton)}
      </div>
    </div>
  );
};
