import { Dispatch, ReactElement, useContext } from "react";
import dayjs from "dayjs";
import { ConditionalWrapper } from "@/components/ConditionalWrapper/ConditionalWrapper";
import {
  BetSlipActions,
  BetSlipState,
  CommonAndEventIdPair,
} from "@/types/betslip";
import { SelectionInterface } from "@/types/api/selections";
import { TimesIcon } from "@/components/icons/TimesIcon";
import { getFormattedOdds } from "@/lib/odds";
import { BetSlipStateContext } from "@/contexts/betSlipContext";
import styles from "./Selection.module.scss";

type SelectionProps = Pick<
  SelectionInterface,
  | "selectionName"
  | "odds"
  | "oddsAmerican"
  | "oddsDecimal"
  | "marketName"
  | "event"
  | "commonID"
  | "id"
  | "isDisabled"
>;

type SelectionPropsWithOnClickAndLastArticle = SelectionProps & {
  dispatch: Dispatch<BetSlipActions>;
  lastArticle: boolean;
};

const delWrapper = (children: ReactElement) => (
  <del data-testid="delWrapper">{children}</del>
);

export const Selection = ({
  selectionName,
  odds,
  oddsDecimal,
  oddsAmerican,
  marketName,
  event,
  id,
  commonID,
  dispatch,
  isDisabled = false,
  lastArticle,
}: SelectionPropsWithOnClickAndLastArticle): ReactElement => {
  const { oddsFormat } = useContext(BetSlipStateContext) as BetSlipState;
  const selectionDescription = `${marketName} ${selectionName}`;
  const commonAndSelectionIdPair: CommonAndEventIdPair = {
    commonID,
    eventId: event.id,
  };

  return (
    <article
      className={`${styles.selectionArticle} ${
        lastArticle ? styles.lastArticle : ""
      }`.trim()}
      data-testid="selection"
    >
      <ConditionalWrapper wrapper={delWrapper} condition={isDisabled}>
        <div className={styles.selectionWrapper}>
          <h4
            className={styles.selectionHeading}
          >{`${selectionName} @ ${getFormattedOdds(
            { odds, oddsAmerican, oddsDecimal },
            oddsFormat
          )}`}</h4>
          <p
            className={styles.selectionMarketName}
            data-testid={`${id}-market-name`}
          >
            {marketName}
          </p>
          <div className={styles.selectionEventContainer}>
            <span data-testid={`${id}-description`}>{event.description}</span>
            <time
              className={styles.selectionTime}
              dateTime={event.date}
              data-testid={`${id}-time`}
              suppressHydrationWarning
            >
              {dayjs(event.date).format("ddd HH:mm")}
            </time>
          </div>
        </div>
      </ConditionalWrapper>
      <div className={styles.selectionActionButtonsContainer}>
        {/* Not using conditionally rendering as we want to allow transitions in the UI*/}
        <button
          className={`${
            isDisabled
              ? styles["selectionActionButton-isDisabled"]
              : styles.selectionActionButton
          }`}
          onClick={() =>
            dispatch({
              type: isDisabled ? "ENABLE" : "DISABLE",
              commonAndSelectionIdPair,
            })
          }
        >
          {isDisabled
            ? `Enable ${selectionDescription}`
            : `Disable ${selectionDescription}`}
          <TimesIcon />
        </button>
        <button
          onClick={() =>
            dispatch({
              type: "REMOVE",
              commonAndSelectionIdPair,
            })
          }
          className={`${
            isDisabled
              ? styles["removeSelectionButton-isDisabled"]
              : styles.removeSelectionButton
          }`}
        >{`Delete`}</button>
      </div>
    </article>
  );
};
