import Image from "next/image";
import React, { Dispatch, ReactElement, useContext } from "react";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { BookmakerOdds } from "@/types/api/odds";
import { BookieOffer } from "@/components/BookieOffer/BookieOffer";
import { bannerLogoUrlFromName } from "@/lib/media";
import { getFormattedOdds } from "@/lib/odds";
import { BetSlipDispatchContext } from "@/contexts/betSlipContext";
import { BetSlipActions, PortalBookmakers } from "@/types/betslip";
import { doesBookmakerSupportAPIBetting } from "@/lib/bookmakerPortal";
import { ArrowRight } from "@/components/icons/ArrowRight";
import { BetSlipStateContext } from "@/contexts/betSlipContext";
import { BetSlipState } from "@/types/betslip";
import { betslipClickoutEventParams, gaEvent } from "@/lib/gtag";
import styles from "@/components/BookieDisplay/BookieDisplay.module.scss";
import { BookieReturns } from "../BookieReturns/BookieReturns";

interface BookieDisplayProps extends BookmakerOdds {
  stake: number;
  bestOdds?: boolean;
}

export const BookieDisplay = ({
  stake,
  bookmaker,
  odds,
  oddsDecimal,
  oddsAmerican,
  betslipURL,
  offers,
  bestOdds,
}: BookieDisplayProps): ReactElement => {
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  const [locale] = useLocaleFromRouter();
  const bookmakerOffers = [...offers];

  const enableAPIBetting = doesBookmakerSupportAPIBetting(bookmaker, locale);

  // fastbet offer is not included in offers array by default for fastbet-supported bookies so need to add the offer here
  if (enableAPIBetting) {
    if (!bookmakerOffers.includes("fastbet")) {
      bookmakerOffers.push("fastbet");
    }
  }

  function renderClickElement() {
    if (enableAPIBetting) {
      const bookie = bookmaker as PortalBookmakers;
      return (
        <button
          data-testid="internal-bookmaker"
          className={styles.circle}
          onClick={() => {
            dispatch({
              type: "SET_PORTAL_BOOKMAKER",
              bookmaker: bookie,
            });
          }}
        >
          <ArrowRight className={styles.arrowRight} fill="none" />
        </button>
      );
    }
    return (
      <a
        href={betslipURL}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          dispatch({
            type: "CLEAR",
          });
          gaBetslipClickoutEvent();
        }}
      >
        <div className={styles.circle}>
          <ArrowRight className={styles.arrowRight} fill="none" />
        </div>
      </a>
    );
  }

  const context = useContext(BetSlipStateContext) as BetSlipState;
  const { selectionsDataAndState, oddsFormat } = context;
  const gaEventParams = betslipClickoutEventParams(
    bookmaker,
    selectionsDataAndState,
    oddsAmerican
  );

  const gaBetslipClickoutEvent = (): void => {
    gaEvent("betslip_clickout", gaEventParams);
  };

  return (
    <div className={styles.bookieDisplayContainer}>
      <div className={styles.betContainer}>
        <div>
          <div className={styles.oddsWrapper}>
            <Image
              src={bannerLogoUrlFromName(bookmaker)}
              alt={`${bookmaker} logo`}
              width={32}
              height={32}
            />
            <span className={styles.odds} data-testid="odds">
              {getFormattedOdds(
                { odds, oddsAmerican, oddsDecimal },
                oddsFormat
              )}
            </span>
          </div>
        </div>
        <BookieReturns stake={stake} oddsDecimal={oddsDecimal} />
      </div>
      <div className={styles.ctaContainer}>
        <div className={styles.offersContainer}>
          <>
            {bestOdds && <BookieOffer offer="bestodds" bookmaker={bookmaker} />}
            {bookmakerOffers.length > 0 &&
              bookmakerOffers.map((item) => {
                return (
                  <BookieOffer key={item} offer={item} bookmaker={bookmaker} />
                );
              })}
          </>
        </div>
        {renderClickElement()}
      </div>
    </div>
  );
};
