import { Dispatch, ReactElement, useContext } from "react";
import { Selection } from "@/components/Selection/Selection";
import {
  BetSlipDispatchContext,
  BetSlipStateContext,
} from "@/contexts/betSlipContext";
import { BetSlipActions, BetSlipState } from "@/types/betslip";
import { BetType } from "@/components/BetType/BetType";
import { isEmptyArray } from "@/lib/array";
import styles from "./Selections.module.scss";

export const Selections = (): ReactElement => {
  const { selectionsDataAndState, activeCommonAndEventIdPairs } = useContext(
    BetSlipStateContext
  ) as BetSlipState;
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;

  if (isEmptyArray(selectionsDataAndState))
    return <BetType selectionCount={0} isEmptySelections={true} />;

  return (
    <div className={styles.selectionsContainer}>
      <BetType selectionCount={activeCommonAndEventIdPairs.length} />
      {selectionsDataAndState.map(
        (
          {
            id,
            selectionName,
            odds,
            oddsDecimal,
            oddsAmerican,
            marketName,
            event,
            commonID,
            isDisabled,
          },
          i
        ) => (
          <Selection
            key={id}
            selectionName={selectionName}
            odds={odds}
            oddsDecimal={oddsDecimal}
            oddsAmerican={oddsAmerican}
            marketName={marketName}
            event={event}
            id={id}
            commonID={commonID}
            dispatch={dispatch}
            isDisabled={isDisabled}
            lastArticle={i === selectionsDataAndState.length - 1}
          />
        )
      )}
      <button
        className={styles.clearAllSelectionsButton}
        onClick={() => dispatch({ type: "CLEAR" })}
      >
        Clear All
      </button>
    </div>
  );
};
