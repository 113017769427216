import { SportBrandShortNames } from "@/types/brand";
import { IS_PRODUCTION } from "@/constants/api";
import { PortalBookmakers } from "@/types/betslip";
import {
  BookmakerPortalReducerAction,
  BookmakerPortalReducerState,
} from "@/types/bookmakerPortal";
import {
  DEFAULT_LOCALE,
  LOCALES_THAT_SUPPORT_API_BETTING,
} from "@/constants/countries";
import { BRANDS_THAT_SUPPORT_API_BETTING } from "@/constants/brands";
import { bookiesThatSupportFastBet } from "@/constants/bet";
import { getActiveBrand } from "./brand";

export const isSupportedAPIBookmaker = (bookmaker: string): boolean => {
  return bookiesThatSupportFastBet.includes(bookmaker as PortalBookmakers);
};

export const doesBookmakerSupportAPIBetting = (
  bookmaker: string,
  locale: string = DEFAULT_LOCALE
): boolean => {
  const brand = getActiveBrand() as SportBrandShortNames;

  if (
    !BRANDS_THAT_SUPPORT_API_BETTING.includes(brand) ||
    !LOCALES_THAT_SUPPORT_API_BETTING.includes(locale.toLowerCase())
  ) {
    return false;
  }
  return isSupportedAPIBookmaker(bookmaker);
};

export const getBookmakerLoginURL = (bookmaker: PortalBookmakers): string => {
  const redirectUrls = {
    paddy_power: `https://identitysso.${bookmaker
      .split("_")
      .join(
        ""
      )}.com/view/login?product=foe&url=https%3A%2F%2Fapi-bettinghub.checkd-dev.com%2F${
      IS_PRODUCTION ? "prod" : "dev"
    }%2Fweb%2F${bookmaker.split("_").join("")}%2Flogin`,
    betfair: `https://identitysso.${bookmaker
      .split("_")
      .join(
        ""
      )}.com/view/login?product=foe&url=https%3A%2F%2Fapi-bettinghub.checkd-dev.com%2F${
      IS_PRODUCTION ? "prod" : "dev"
    }%2Fweb%2F${bookmaker.split("_").join("")}%2Flogin`,
    bet365: `https://gateway-members.bet365.com/v1/auth/OAuth/LogOn?client_id=D293F635-BEEA-42CA-B502-D186A15E852D&scope=sports+members&response_type=code&redirect_uri=https://api-bettinghub.checkd-dev.com/${
      IS_PRODUCTION ? "prod" : "dev"
    }/web/bet365/login&state=${
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    }`,
  };

  const redirectUrl = redirectUrls[bookmaker];
  return redirectUrl;
};

export const getDepositLink = (bookmaker: PortalBookmakers): string => {
  const depositUrls = new Map<PortalBookmakers, string>([
    [
      "paddy_power",
      "https://myfunds.paddypower.com/deposit?prod=56&returnURL=https%3A%2F%2Fmyaccount.paddypower.com%2Faccount%2Fnavigation%3Fprod%3D56",
    ],
    [
      "betfair",
      "https://myfunds.betfair.com/deposit?prod=56&returnURL=https%3A%2F%2Fmyaccount.betfair.com%2Faccount%2Fnavigation%3Fprod%3D56",
    ],
    [
      "bet365",
      "https://members.bet365.com/Members/Authenticated/Bank/Deposit/",
    ],
  ]);

  return depositUrls.get(bookmaker) || "#";
};

export const getSignUpLink = (bookmaker: PortalBookmakers): string => {
  const bookmakerLinks = new Map([
    [
      "paddy_power",
      "https://media.paddypower.com/redirect.aspx?pid=12766816&bid=5601",
    ],
    ["betfair", "https://ads.betfair.com/redirect.aspx?pid=3709671&bid=10709"],
    [
      "bet365",
      "https://www.bet365.com/olp/open-account/?affiliate=365_00924611",
    ],
  ]);

  return bookmakerLinks.get(bookmaker) || "#";
};

export const bookmakerPortalReducer = (
  state: BookmakerPortalReducerState,
  action: BookmakerPortalReducerAction
): BookmakerPortalReducerState => {
  switch (action.type) {
    case "setError":
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case "setAccountBalance":
      return {
        ...state,
        accountBalance: action.accountBalance,
      };
    case "setView":
      return {
        ...state,
        view: action.view,
      };
    case "setReceipt":
      return {
        ...state,
        receipt: action.receipt,
        isLoading: false,
      };
    case "setBookmakerLogin":
      return {
        ...state,
        bookmakerLogin: action.bookmakerLogin,
      };
    case "setLoading":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "logout":
      return {
        ...state,
        bookmakerLogin: null,
        receipt: null,
        accountBalance: 0,
        view: "",
        isLoading: false,
      };
  }
};
